.button {
	background-color: #ddd;
	color: #777;
	padding: 4px 7px;
	cursor: pointer;
	font-size: 0.9em;
	border-radius: 10px 10px 0 0;
	border-top: 1px solid #aaa;
	border-left: 1px solid #aaa;
	border-right: 1px solid #aaa;
}

.button:hover {
	background-color: #aaa;
	color: #333;
}
