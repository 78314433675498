@import 'variables.scss';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/bootstrap';

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css');
@import '~react-image-gallery/styles/scss/image-gallery.scss';

.form-control,
.form-select,
.form-check-input {
	background-color: white;
}

main {
	padding-bottom: 80px;
}

.label-sm {
	color: grey;
	margin-bottom: 0em;
	font-size: 0.85em;
}

.source-product label {
	color: #333;
}
