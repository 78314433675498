.item {
	background-color: white;
	cursor: pointer;
}

.item:hover {
	background-color: #ddd;
}

.img {
	width: 100px;
	background-color: black;
	object-fit: contain;
}

.void-img {
	width: 100px;
	min-height: 100px;
	background-color: black;
	object-fit: contain;
}

.text {
	padding: 10px;
	padding-left: 20px;
}

.grey {
	color: grey;
}
