.item {
	padding-right: 20px;
	gap: 20px;
	cursor: pointer;
	background-color: white;
}

.item:hover {
	background-color: #ddd;
}

.selected {
	background-color: #eee9dd;
}

.labelstyle {
	color: #999;
	font-size: 0.8em;
	margin-right: 6px;
}

.pictureplaceholder {
	height: 100px;
	width: 100px;
	background-color: grey;
}

.picture {
	height: 100px;
	width: 100px;
}
